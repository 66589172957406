import PortableTextPlus from "@/components/portable-text-plus";
import SegmentContainer from "@/components/segments/segment-container";
import Badge from "@/components/badge";
import CallToActionButton from "@/components/call-to-action-button";
import classNames from "classnames";

export default function NumberedListLargeNumbersLeftRows({
  segment,
  ...props
}) {
  return (
    <SegmentContainer
      className={props.paddingClasses}
      style={{ ...props.bgImageStyle, ...props.bgColorStyle }}
    >
      {segment.heading && (
        <div className="mb-8 lg:mb-12 leading-snug font-semibold text-xl md:text-3xl">
          <PortableTextPlus value={segment.heading} />
        </div>
      )}
      <ol
        className={classNames(
          "grid gap-y-12 md:gap-y-16 gap-x-6 md:gap-x-8 lg:gap-x-10",
          props.widthClasses,
          props.gridColsClasses[segment.numBlocksInRow] ||
            props.gridColsClasses["3"]
        )}
      >
        {segment.blocks.map((block, index) => {
          return (
            <li
              className="grid grid-cols-[20%_auto] gap-4 md:pr-4"
              key={`${index}-${block.heading}`}
            >
              <div className="text-5xl lg:text-6xl text-pink text-left font-light italic">
                {String(index + 1).padStart(2, "0")}
              </div>
              <div className="">
                {block.heading && (
                  <div
                    className={classNames(
                      "font-semibold text-lg text-dark-purple",
                      props.headingSizeClasses[block.headingSize] || "text-lg"
                    )}
                  >
                    <PortableTextPlus value={block.heading} />
                  </div>
                )}
                {block.content && (
                  <div className="pt-2 md:pt-4 leading-snug prose">
                    <PortableTextPlus value={block.content} />
                  </div>
                )}
                {block.badges && (
                  <div className="mt-3 space-x-2">
                    {block.badges.map((badge) => (
                      <Badge key={badge.title} {...badge} />
                    ))}
                  </div>
                )}
                {block.callToActions && (
                  <div className="mt-4 space-y-4">
                    {block.callToActions.map((callToAction) => (
                      <CallToActionButton
                        className="mr-4"
                        key={callToAction._key}
                        href={callToAction.url}
                        thin={true}
                        {...callToAction}
                      >
                        {callToAction.title}
                      </CallToActionButton>
                    ))}
                  </div>
                )}
              </div>
            </li>
          );
        })}
      </ol>
    </SegmentContainer>
  );
}
